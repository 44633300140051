<template lang="pug">
.player
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(name="seasonInquiry", v-model="seasonInquiryType")
            option(:value="index", v-for="(item, index) in leagueData.seasons") {{ item.name }}
  v-container
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 球員排行
        v-divider.d-block
    v-row.mt-5
      players-ranking-card-slider(
        v-for="(n, index) in rankingTitles.length",
        :key="index",
        :playersRankingList="index == 0 ? playerOffensiveList : playerDefensiveList",
        :rankingTitles="rankingTitles[index]",
        @playerClick="playerClick"
      )
  v-container(v-if="hasForeign")
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 本土球員排行
        v-divider.d-block
    v-row.mt-5
      players-ranking-card-slider(
        v-for="(n, index) in rankingTitles.length",
        :key="index",
        :playersRankingList="index == 0 ? nativePlayerOffensiveList : nativePlayerDefensiveList",
        :rankingTitles="rankingTitles[index]",
        @playerClick="playerClick"
      )
  v-container(v-if="hasForeign")
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 洋將球員排行
        v-divider.d-block
    v-row.mt-5
      players-ranking-card-slider(
        v-for="(n, index) in rankingTitles.length",
        :key="index",
        :playersRankingList="index == 0 ? foreignPlayerOffensiveList : foreignPlayerDefensiveList",
        :rankingTitles="rankingTitles[index]",
        @playerClick="playerClick"
      )
  v-divider(style="margin-top: 60px; border-width: 2px")
  v-container.mb-10
    v-row.mt-16
      v-col(cols="12")
        h5.normal_title_h5 全部球員
    v-row.mt-5(justify="end")
      v-col(cols="6", md="3")
        select(v-model="teamSelect", @change="filterChange")
          option(value="ALL") 全部
          option(v-for="(team, index) in teamFilterList", :value="team") {{ team }}
      v-col(cols="6", md="3")
        input.search-input(
          type="text",
          placeholder="請輸入球員名稱",
          v-model="searchText"
        )
    v-row.table_row
      v-col#sticky-nav.table_col.table.position-relative.mt-4(cols="12")
        v-overlay(
          absolute,
          :value="currentPlayerData.length == 0",
          opacity="0.1"
        )
          v-progress-circular(indeterminate, color="primary")
        table(style="height: 500px")
          thead
            tr.top_head
              sticky-column.bdr2(colspan="1", :offset="0")
              th(colspan="3")
              th(colspan="5")
              th.bdr(colspan="3") 投籃
              th.bdr(colspan="3") 兩分
              th.bdr(colspan="3") 三分
              th.bdr(colspan="3") 罰球
              th.bdr(colspan="2") 籃板
              th(colspan="5")
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 120px; min-width: 120px",
                :title="'球員'",
                :offset="0"
              )
              th
                span 隊伍
              th(
                style="cursor: pointer",
                @click="sortClick('record_matches', 'INFO')",
                :class="{ sort_up: sortValue.type == 'record_matches' && sortValue.order == 'ASC', sort_down: sortValue.type == 'record_matches' && sortValue.order == 'DESC' }"
              )
                span 出賽
                font-awesome-icon.ml-2(:icon="getTableSort('record_matches')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_secs', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_secs' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_secs' && sortValue.order == 'DESC' }"
              )
                span 時間
                font-awesome-icon.ml-2(:icon="getTableSort('avg_secs')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_pts', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_pts' && sortValue.order == 'DESC' }"
              )
                span 得分
                font-awesome-icon.ml-2(:icon="getTableSort('avg_pts')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_reb' && sortValue.order == 'DESC' }"
              )
                span 籃板
                font-awesome-icon.ml-2(:icon="getTableSort('avg_reb')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_ast', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ast' && sortValue.order == 'DESC' }"
              )
                span 助攻
                font-awesome-icon.ml-2(:icon="getTableSort('avg_ast')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_stl', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_stl' && sortValue.order == 'DESC' }"
              )
                span 抄截
                font-awesome-icon.ml-2(:icon="getTableSort('avg_stl')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_blk', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_blk' && sortValue.order == 'DESC' }"
              )
                span 阻攻
                font-awesome-icon.ml-2(:icon="getTableSort('avg_blk')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fga')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fg_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fg_percent')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_two_pt_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_two_pt_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_two_pt_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('avg_two_pt_fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_two_pt_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_two_pt_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_two_pt_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('avg_two_pt_fga')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_two_pt_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_two_pt_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_two_pt_fg_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_two_pt_fg_percent')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('avg_three_pt_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_three_pt_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_three_pt_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_three_pt_fgm')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('avg_three_pt_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_three_pt_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_three_pt_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_three_pt_fga')"
                )
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_three_pt_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_three_pt_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_three_pt_fg_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_three_pt_fg_percent')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('avg_ftm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_ftm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ftm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('avg_ftm')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_fta', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fta' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fta' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fta')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_ft_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_ft_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ft_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('avg_ft_percent')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_off_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_off_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_off_reb' && sortValue.order == 'DESC' }"
              )
                span 進攻
                font-awesome-icon.ml-2(:icon="getTableSort('avg_off_reb')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_def_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_def_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_def_reb' && sortValue.order == 'DESC' }"
              )
                span 防守
                font-awesome-icon.ml-2(:icon="getTableSort('avg_def_reb')")

              th(
                style="cursor: pointer",
                @click="sortClick('avg_tov', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_tov' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_tov' && sortValue.order == 'DESC' }"
              )
                span 失誤
                font-awesome-icon.ml-2(:icon="getTableSort('avg_tov')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_pf', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_pf' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_pf' && sortValue.order == 'DESC' }"
              )
                span 犯規
                font-awesome-icon.ml-2(:icon="getTableSort('avg_pf')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_plus_minus', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_plus_minus' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_plus_minus' && sortValue.order == 'DESC' }"
              )
                span +/-
                font-awesome-icon.ml-2(:icon="getTableSort('avg_plus_minus')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_eff', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_eff' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_eff' && sortValue.order == 'DESC' }"
              )
                span EFF
                font-awesome-icon.ml-2(:icon="getTableSort('avg_eff')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_at_ratio', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_at_ratio' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_at_ratio' && sortValue.order == 'DESC' }"
              )
                span A/T
                font-awesome-icon.ml-2(:icon="getTableSort('avg_at_ratio')")
          tbody
            box(
              v-for="(item, index) in currentPlayerData",
              :key="index",
              :item="item",
              :title="item.info.name",
              :showTeam="true",
              style="cursor: pointer",
              @rowClick="playerClick(item)"
            )

    v-row
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
</template>

<script>
import PlayersRankingCardSlider from "@/components/league/PlayersRankingCardSlider";
import StickyColumn from "@/components/common/Table/StickyColumn";
import Box from "@/components/common/Box";
import TopHead from "@/components/common/Box/TopHead";

import { getLeagueList } from "@/api/league";
import { getPlayerRankingData, getPlayerSeasonData } from "@/api/player";

export default {
  name: "LeaguePlayerOverview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    PlayersRankingCardSlider,
    StickyColumn,
    Box,
    TopHead,
  },
  data() {
    return {
      league: this.$route.params.league,
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      leagueData: {
        info: {},
      },
      seasonInquiryType: 0,

      teamFilterList: [],
      teamSelect: "ALL",

      playerRankingData: {},
      foreignPlayerRankingData: {},
      nativePlayerRankingData: {},
      playerSeasonData: [],
      filterPlayerSeasonData: [],
      sortPlayerSeasonData: [],
      currentPlayerData: [],

      teamInquiryType: 0,
      positionInquiryType: 0,
      teamInquiryList: ["球隊", "政治大學"],
      positionInquiryList: ["位置", "後衛"],
      headTitleList: ["球員", "隊伍", "背號", "位置", "身高", "體重"],

      rankingTitles: [
        {
          title: "進攻排行",
          titleEng: "Offensive Leaders",
        },
        {
          title: "防守排行",
          titleEng: "Defensive Leaders",
        },
      ],

      playerOffensiveList: [],
      foreignPlayerOffensiveList: [],
      nativePlayerOffensiveList: [],
      playerDefensiveList: [],
      foreignPlayerDefensiveList: [],
      nativePlayerDefensiveList: [],
      hasForeign: false,
      sortValue: {
        type: "",
        order: "",
      },
      searchText: "",

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    seasonInquiryType() {
      this.currentPage = 1;
      this.totalPages = 1;
      this.teamFilterList = [];
      this.teamSelect = "ALL";
      this.playerRankingData = {};
      this.foreignPlayerRankingData = {};
      this.nativePlayerRankingData = {};
      this.playerSeasonData = [];
      this.filterPlayerSeasonData = [];
      this.sortPlayerSeasonData = [];
      this.currentPlayerData = [];
      this.teamInquiryType = 0;
      this.positionInquiryType = 0;
      this.playerOffensiveList = [];
      this.foreignPlayerOffensiveList = [];
      this.nativePlayerOffensiveList = [];
      this.playerDefensiveList = [];
      this.foreignPlayerDefensiveList = [];
      this.nativePlayerDefensiveList = [];
      this.sortValue = {
        type: "",
        order: "",
      };
      this.searchText = "";

      this.initData();
    },
    currentPage() {
      this.getCurrentPlayerData();
    },
    searchText() {
      this.filterChange();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getLeagueList();
      await this.getPlayerRankingData();
      if (this.hasForeign) {
        this.getForeignPlayerRankingData();
        this.getNativePlayerRankingData();
      }
      await this.getPlayerSeasonData();
    },
    async getLeagueList() {
      await getLeagueList().then((response) => {
        for (let index in response.data) {
          let temp = response.data[index];
          if (temp.name === this.league) {
            this.leagueData = Object.assign({}, temp);
            break;
          }
        }
      });
    },
    async getPlayerRankingData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        ranking: 10,
      };
      await getPlayerRankingData(temp).then((response) => {
        this.playerRankingData = response.data;
        this.hasForeign = response.data.has_foreign;
        let score = {
          type: "score",
          title: "得分",
          rankings: this.playerRankingData.avg_pts,
        };
        this.playerOffensiveList.push(score);

        let threePoints = {
          type: "threePointScore",
          title: "三分得分",
          rankings: this.playerRankingData.avg_three_pts,
        };
        this.playerOffensiveList.push(threePoints);

        let assist = {
          type: "assist",
          title: "助攻",
          rankings: this.playerRankingData.avg_ast,
        };
        this.playerOffensiveList.push(assist);

        let rebound = {
          type: "rebound",
          title: "籃板",
          rankings: this.playerRankingData.avg_reb,
        };
        this.playerDefensiveList.push(rebound);

        let steal = {
          type: "steal",
          title: "抄截",
          rankings: this.playerRankingData.avg_stl,
        };
        this.playerDefensiveList.push(steal);

        let block = {
          type: "block",
          title: "阻攻",
          rankings: this.playerRankingData.avg_blk,
        };
        this.playerDefensiveList.push(block);
      });
    },
    async getForeignPlayerRankingData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        ranking: 10,
        nation: "FOREIGN",
      };
      await getPlayerRankingData(temp).then((response) => {
        this.foreignPlayerRankingData = response.data;
        let score = {
          type: "score",
          title: "得分",
          rankings: this.foreignPlayerRankingData.avg_pts,
        };
        this.foreignPlayerOffensiveList.push(score);

        let threePoints = {
          type: "threePointScore",
          title: "三分得分",
          rankings: this.foreignPlayerRankingData.avg_three_pts,
        };
        this.foreignPlayerOffensiveList.push(threePoints);

        let assist = {
          type: "assist",
          title: "助攻",
          rankings: this.foreignPlayerRankingData.avg_ast,
        };
        this.foreignPlayerOffensiveList.push(assist);

        let rebound = {
          type: "rebound",
          title: "籃板",
          rankings: this.foreignPlayerRankingData.avg_reb,
        };
        this.foreignPlayerDefensiveList.push(rebound);

        let steal = {
          type: "steal",
          title: "抄截",
          rankings: this.foreignPlayerRankingData.avg_stl,
        };
        this.foreignPlayerDefensiveList.push(steal);

        let block = {
          type: "block",
          title: "阻攻",
          rankings: this.foreignPlayerRankingData.avg_blk,
        };
        this.foreignPlayerDefensiveList.push(block);
      });
    },
    async getNativePlayerRankingData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        ranking: 10,
        nation: "NATIVE",
      };
      await getPlayerRankingData(temp).then((response) => {
        this.nativePlayerRankingData = response.data;

        let score = {
          type: "score",
          title: "得分",
          rankings: this.nativePlayerRankingData.avg_pts,
        };
        this.nativePlayerOffensiveList.push(score);

        let threePoints = {
          type: "threePointScore",
          title: "三分得分",
          rankings: this.nativePlayerRankingData.avg_three_pts,
        };
        this.nativePlayerOffensiveList.push(threePoints);

        let assist = {
          type: "assist",
          title: "助攻",
          rankings: this.nativePlayerRankingData.avg_ast,
        };
        this.nativePlayerOffensiveList.push(assist);

        let rebound = {
          type: "rebound",
          title: "籃板",
          rankings: this.nativePlayerRankingData.avg_reb,
        };
        this.nativePlayerDefensiveList.push(rebound);

        let steal = {
          type: "steal",
          title: "抄截",
          rankings: this.nativePlayerRankingData.avg_stl,
        };
        this.nativePlayerDefensiveList.push(steal);

        let block = {
          type: "block",
          title: "阻攻",
          rankings: this.nativePlayerRankingData.avg_blk,
        };
        this.nativePlayerDefensiveList.push(block);
      });
    },
    async getPlayerSeasonData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,box",
      };
      await getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonData = response.data;
        this.filterPlayerSeasonData = Object.assign([], this.playerSeasonData);
        this.sortPlayerSeasonData = Object.assign([], this.playerSeasonData);

        this.getTeamFilterList();
        this.getCurrentPlayerData();
      });
    },
    getTeamFilterList() {
      this.teamFilterList = [];
      for (let index in this.sortPlayerSeasonData) {
        let temp = this.sortPlayerSeasonData[index];
        if (!this.teamFilterList.includes(temp.info.team_name)) {
          this.teamFilterList.push(temp.info.team_name);
        }
      }
    },
    filterChange() {
      let tempList = Object.assign([], this.playerSeasonData);

      if (this.teamSelect != "ALL") {
        this.filterPlayerSeasonData = tempList.filter((player) => {
          return (
            player.info.team_name == this.teamSelect &&
            player.info.name.includes(this.searchText)
          );
        });
      } else {
        this.filterPlayerSeasonData = tempList.filter((player) => {
          return player.info.name.includes(this.searchText);
        });
      }

      this.sortValue.type = "";
      this.sortValue.order = "";
      this.sortPlayerSeasonData = Object.assign(
        [],
        this.filterPlayerSeasonData
      );
      this.getCurrentPlayerData();
    },
    getCurrentPlayerData() {
      this.totalPages = Math.ceil(
        this.sortPlayerSeasonData.length / this.perPage
      );
      this.currentPlayerData = [];
      let number = this.perPage * this.currentPage;
      this.currentPlayerData = this.sortPlayerSeasonData.slice(
        number - this.perPage,
        number
      );
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          league: this.leagueData.id,
          season: this.leagueData.seasons[this.seasonInquiryType].id,
          id: player.info.id,
        },
      });
    },
    sortClick(type, sortData) {
      if (type == this.sortValue.type) {
        if (this.sortValue.order == "DESC") {
          this.sortValue.order = "ASC";
          this.sortPlayerSeasonData = this.sortData(
            this.filterPlayerSeasonData,
            type,
            sortData,
            "ASC"
          );
        } else if (this.sortValue.order == "ASC") {
          this.sortValue.type = "";
          this.sortValue.order = "";
          this.sortPlayerSeasonData = this.filterPlayerSeasonData;
        }
      } else {
        this.sortValue.type = type;
        this.sortValue.order = "DESC";
        this.sortPlayerSeasonData = this.sortData(
          this.filterPlayerSeasonData,
          type,
          sortData,
          "DESC"
        );
      }
      this.getCurrentPlayerData();
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortData(allList, sortKey, sortData, order) {
      let tempList = Object.assign([], allList);
      if ("INFO" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.info[sortKey] - b.info[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.info[sortKey] - a.info[sortKey];
          });
        }
      } else if ("BOX" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.box[sortKey] - b.box[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.box[sortKey] - a.box[sortKey];
          });
        }
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";

table {
  text-align: center;
}

#sticky-nav {
  height: calc(80vh - 72px);
  table {
    .top_head {
      th {
        top: 0;
      }
    }
    .bottom_head {
      th {
        top: 38px;
      }
    }
  }
}

select {
  background: #ffffff;
  border: 1px solid #487aa4;
  background: url("~@/assets/img/fantasy/f_dropdwon_arrow.png") no-repeat right
    center;
  background-position: calc(100% - 10px) center;
}

@media (max-width: 599px) {
  #sticky-nav {
    table {
      .top_head {
        th {
          top: 0;
        }
      }

      .bottom_head {
        th {
          top: 31.69px;
        }
      }
    }
  }
}
</style>
